$font-path: 'https://cdn.app.sbb.ch/fonts/v1_6_subset/';
@import 'Globals.scss';
@import '../node_modules/ol/ol.css';
@import '@geops/react-ui/themes/default/components.scss';
@import 'react-spatial/themes/default/components.scss';

/**
* Load fonts.
* $font-path comes from process.env.REACT_APP_FONT_PATH, see config-overrides.js.
*/
@mixin loadFont($fontFamily, $path) {
  @font-face {
    font-family: #{$fontFamily};
    src:
      url('#{$font-path}#{$path}.woff') format('woff'),
      url('#{$font-path}#{$path}.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
  }
}

@include loadFont(
  'SBBWeb Roman',
  'SBBWeb-Roman'
);
@include loadFont(
  'SBBWeb Bold',
  'SBBWeb-Bold'
);
@include loadFont(
  'SBBWeb Light',
  'SBBWeb-Light'
);

body {
  overscroll-behavior: contain;

  &.tm-pointer {
    cursor: pointer;
  }
}
