$font-path: 'https://cdn.app.sbb.ch/fonts/v1_6_subset/';
@import '../../Globals.scss';

.tm-station-plan {
  &.tm-w-xs,
  &.tm-w-s,
  &.tm-w-m {
    .tm-header-logo {
      display: none;
    }
  }

  .tm-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 2;

    .tm-header-flex {
      display: flex;
      width: 100%;
      height: 100%;
      justify-content: space-between;
    }

    .tm-header-title {
      font-size: 25px;
      font-weight: normal;
      margin: 0;
    }

    .tm-header-title-without-menu {
      padding-left: 20px;
    }

    .tm-menu-button {
      color: initial;
      font-size: 1.5em;
      border: 0;
      margin-left: 10px;

      &.tm-bf-focus {
        outline-offset: -3px;
      }

      .tm-burger {
        cursor: pointer;
        display: flex;
        position: relative;
        flex-direction: column;
        align-items: center;
        height: 28px;
        width: 28px;
        padding-top: 2px;

        span {
          background-color: #333;
          width: 18px;
          height: 2px;
          margin: 3px;
          transform: rotate(0deg);
          transition: 0.25s ease-in-out;
        }

        &.tm-burger-active {
          padding-top: 0;
          flex-direction: inherit;

          .tm-burger-line-first,
          .tm-burger-line-third {
            transition: all 0.3s ease;
            transform-origin: left center;
            position: absolute;
            width: 24px;
            top: 10px;
          }

          .tm-burger-line-first {
            transform: rotate(45deg);
            top: 2px;
            left: 3px;
          }

          .tm-burger-line-second {
            opacity: 0;
          }

          .tm-burger-line-third {
            transform: rotate(-45deg);
            top: 19px;
            left: 3px;
          }
        }

        &:hover {
          span {
            background-color: $btn-primary-color;
          }
        }
      }
    }

    .tm-toggle-button {
      width: 44px;
      height: 100%;
      font-size: 2.5em;
    }

    .tm-header-right {
      display: flex;
      align-items: center;

      .tm-toggle-search {
        display: none;
      }

      .tm-header-logo {
        width: 188px;
        height: 22px;
        padding: 0 20px 0 10px;
      }
    }
  }

  &.tm-w-xs,
  &.tm-w-s {
    .tm-header .tm-menu-button {
      padding-left: 0;
    }
  }

  &.tm-w-xs {
    .tm-header-right {
      .tm-toggle-search {
        z-index: 1;
        display: block;
        margin-right: 15px;
        width: 22px;
        height: 19px;

        svg {
          path {
            stroke: $gray-light;
          }
        }
      }
    }

    .tm-header-title {
      font-size: 16px;
    }
  }
}
