$font-path: 'https://cdn.app.sbb.ch/fonts/v1_6_subset/';
@import '../../Globals.scss';

.tm-single-value-wrapper {
  border: 1px solid white;
  height: 100%;
  color: $gray-light;
  align-items: center;
  justify-content: space-around;
  display: flex;
  padding: 5px;

  .tm-single-value-selected {
    padding-right: 5px;
  }

  .tm-select-toggle {
    height: 20px;
    width: 20px;
    transition: transform 0.4s ease-in-out;
    display: inline-block;
  }

  .tm-select-toggle-rotate {
    transform-origin: 50% 50%;
    transform: rotate(-180deg);
  }
}

.tm-w-l,
.tm-w-m,
.tm-w-s,
.tm-w-xs {
  .tm-single-value-selected {
    display: none;
  }
}

.tm-select-menu-opened {
  border: 1px solid #aaa;
}
