$font-path: 'https://cdn.app.sbb.ch/fonts/v1_6_subset/';
.tm-legend-menu {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: absolute;
  bottom: 45px;
  left: 20px;
  right: 20px;

  .tm-legend-menu-content {
    box-shadow: 0 0 7px rgba(0, 0, 0, 0.4);
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #aaa;
    background: white;
    flex-wrap: wrap;
    max-width: 100%; // IE 11

    &,
    li {
      list-style: none;
      margin: 0;
      padding: 0;
    }
  }

  .tm-legend-color {
    width: 10px;
    height: 10px;
    border-radius: 10px;
    margin-right: 10px;
  }

  .tm-menu-tooltip {
    top: -40px;
    display: none;
  }
}
