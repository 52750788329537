$font-path: 'https://cdn.app.sbb.ch/fonts/v1_6_subset/';
@import '../../../node_modules/prismjs/themes/prism-coy.css';

body {
  display: block;
  margin: 0;
  color: #333;
}

header {
  height: 60px;
  position: relative;
  width: 100%;
  color: #61849c;
  border-bottom: 2px solid #61849c;
  background-color: white;
  display: flex;
  align-items: center;

  h1 {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    font-size: 20px;
    margin: 0 0 0 1.5em;
    color: #61849c;
    font-weight: bold;
  }
}

.tm-doc {
  margin: auto;
  margin-bottom: 20px;
  width: 950px;
  display: flex;
  flex-direction: column;
  font-family:
    'Segoe UI',
    'Roboto',
    'Oxygen',
    'Ubuntu',
    'Cantarell',
    'Fira Sans',
    'Droid Sans',
    'Helvetica Neue',
    sans-serif;

  h1 {
    font-weight: normal;
  }
}

.tm-parameters,
.tm-preview,
.tm-code {
  position: relative;
  width: 100%;
  height: 600px;
}

.tm-parameters {
  height: auto;

  input,
  select {
    width: 100%;
  }

  table {
    width: 100%;
  }

  thead tr {
    border-bottom: 3px #e8e8e8 solid;
  }

  th,
  td {
    padding-right: 16px;
    padding-bottom: 8px;
    color: #333;
    font-size: 13px;
  }

  th {
    text-align: left;
    font-weight: bold;
    white-space: nowrap;
  }

  td {
    padding-top: 8px;
    vertical-align: top;
  }

  td:nth-child(1),
  td:nth-child(2),
  td:nth-child(3) {
    font-family: Consolas, 'Liberation Mono', Menlo, monospace;
  }

  td:nth-child(1) {
    color: #690;
  }

  td:nth-child(2) {
    color: #905;
  }

  svg {
    font-size: 1.5rem;
    color: rgba(0, 0, 0, 0.54);
  }
}

.tm-preview,
.tm-code {
  border: 1px solid lightgray;
}

.tm-code {
  padding: 5px;
  height: auto;
  background-color: #f5f5f5;

  div[role='button'] {
    position: absolute;
    right: 10px;
    top: 10px;
  }
}
