$font-path: 'https://cdn.app.sbb.ch/fonts/v1_6_subset/';
.tm-imprint {
  width: 600px;
  padding-left: 20px;
  margin-bottom: 50px;

  a {
    color: #337ab7;
    text-decoration: none;
  }
}
