$font-path: 'https://cdn.app.sbb.ch/fonts/v1_6_subset/';
.tm-notification {
  opacity: 0;
  animation: toggle 3s;
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  margin-bottom: 5px;

  > div {
    display: flex;
    align-items: center;
    margin: 5px 10px;
  }
}

@keyframes toggle {
  0% {
    opacity: 0;
  }

  15% {
    opacity: 1;
  }

  75% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}
