$font-path: 'https://cdn.app.sbb.ch/fonts/v1_6_subset/';
@import '../../Globals.scss';

.tm-station-plan {
  .tm-print-button {
    width: 45%;
    display: flex;
    align-items: center;
    justify-content: left;

    a {
      color: #333;
      display: flex;
      align-items: center;

      svg {
        width: 25px;
        height: 25px;
        padding-right: 10px;

        path {
          stroke-width: 1.5;
        }
      }

      &:hover {
        svg {
          path {
            stroke: $brand-primary;
          }
        }
      }
    }
  }
}
