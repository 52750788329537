$font-path: 'https://cdn.app.sbb.ch/fonts/v1_6_subset/';
@import '../../Globals.scss';

.tm-station-switcher {
  width: 430px;
  z-index: 2;
  overflow: auto;
  border-left-width: 0;
  padding: 10px;

  &.tm-hidden {
    opacity: 0;
  }

  a {
    display: inline-block;
    width: 32%;
    padding: 5px 0;
    text-decoration: none;
    color: black;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    &.tm-selected {
      color: $btn-primary-color-hover;

      &:hover {
        color: $btn-primary-color-hover;
      }
    }
  }
}

.tm-station-title {
  border-top: 1px solid #aaa;
  padding: 10px 0 5px 0;
  font-size: 20px;
  font-family: $font-family-bold;
}

.tm-station-top {
  border-bottom: 1px solid #aaa;
  margin-bottom: 5px;
}

.tm-w-xs {
  .tm-station-switcher {
    width: calc(100% - 20px);

    a {
      width: 49%;
    }
  }
}

// used for fading in newly added containers
// see https://codeburst.io/animating-dynamically-created-elements-pure-css-c864fdb6e366
@keyframes fade {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
