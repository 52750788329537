$font-path: 'https://cdn.app.sbb.ch/fonts/v1_6_subset/';
@import '../Globals.scss';

.tm-station-plan {
  height: 100%;
  width: 100%;
  position: absolute;
  overflow: hidden;
  font-family: $font-family;
  font-size: $font-size-base;
  margin: 0;

  @keyframes blink {
    50% {
      opacity: 0.4;
    }
  }

  div[role='button']:not(.tm-popup-content),
  button {
    cursor: pointer;
  }

  input {
    font-family: inherit;
    font-size: inherit;
    color: inherit;
  }

  b {
    font-family: $font-family-bold;
  }

  div,
  li,
  input {
    &:focus {
      outline: none;
    }
  }

  a {
    color: initial;
    text-decoration: none;
    transition: color 0.2s ease;

    &:hover {
      color: $btn-primary-color;
    }

    &:focus {
      outline: none;
    }
  }

  // TOFIX: Avoid the Suspense component to hide the app when translations are loading
  & > div[style] {
    display: block !important;
  }

  // start: override SBB styles
  // https://issues-ext.sbb.ch/browse/SBBCH-4550
  table th {
    font-family: $font-family;
  }

  input[type='text'] {
    margin-top: 0;
  }

  *,
  *::before,
  *::after {
    box-sizing: initial;
    // Re-write sbb style that breaks tooltip & popup
    line-height: normal;
  }
  // end: override SBB styles

  // Hiddden text use for screen reader.
  .tm-screen-reader-text {
    visibility: hidden;
    width: 1px;
    display: inline-block;
  }

  .rs-map {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;

    &.tm-bf-focus {
      animation: blink 0.3s linear;
      z-index: 0;
    }
  }

  .tm-ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .tm-bf-focus {
    z-index: 99; // Hightest z-index
    outline: 4px solid rgba(#003d85, 0.5) !important;
    outline-offset: 5px;
  }

  .tm-copyright {
    position: absolute;
    bottom: 22px;
    right: 0;
    text-align: right;
    padding: 5px;
    font-size: 0.8em;
  }

  .tm-select {
    background: #fff;
    width: 120px;
    height: 26px;
    padding: 4px;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;

    &:focus {
      box-shadow:
        inset 0 1px 1px rgba(0, 0, 0, 0.075),
        0 0 8px rgba(102, 175, 233, 0.6);
    }
  }

  .tm-zooms-wrapper {
    position: absolute;
    right: 20px;
    bottom: 45px;
    z-index: 0;

    .tm-round-white {
      background: white;
      box-shadow: 0 0 7px rgba(0, 0, 0, 0.7);
      transition: box-shadow 0.5s ease;
      border-radius: 50%;

      &:hover {
        box-shadow: 0 0 12px rgba(0, 0, 0, 0.7);
      }
    }

    div:not(:first-child) {
      margin-top: 20px;
    }
  }

  .tm-button-bar {
    position: absolute;
    right: 20px;
    top: 75px;

    .tm-button {
      border: 1px solid #aaa;
      border-bottom-color: white;

      :not(:last-child) {
        border-bottom: none;
      }

      &.tm-active {
        color: white;
        border-color: $grey-selected;
      }
    }

    .tm-floor-button-wrapper {
      .tm-square-white {
        transition:
          border 0.5s ease,
          color 0.5s ease;

        &.tm-active {
          background: $grey-selected;
        }

        &:hover:not(.tm-active) {
          color: $grey-selected;
          border: 1px solid $grey-selected;

          svg {
            polyline,
            circle,
            path {
              stroke: #000;
            }
          }
        }
      }
    }

    .tm-notification {
      position: absolute;
      right: 110%;
      width: 300px;
      top: 0;
      bottom: 20px;
      margin: auto;
      height: 45px;
    }
  }

  .tm-footer {
    justify-content: flex-end;

    .tm-select-links {
      display: inline-block;
    }

    #ot-sdk-btn {
      font-family: inherit;
      font-size: inherit;
      border: none;
      background: transparent;
      color: black;
      padding: 0;
      transition: none;

      &:hover {
        background: transparent;
        color: #b80000;
        text-decoration: underline;
      }
    }
  }

  // Footer disabled
  &.tm-footer-disabled {
    .tm-legend-menu {
      bottom: 20px;
    }

    .tm-copyright {
      bottom: 0;
    }

    .tm-zooms-wrapper {
      bottom: 44px;
    }
  }

  // Search disabled
  &.tm-header-disabled .tm-button-bar {
    top: 20px;
  }

  &.tm-w-xs,
  &.tm-w-s,
  &.tm-w-m {
    .tm-legend-menu {
      .tm-legend-menu-title {
        // For screen reader.
        position: absolute;
        visibility: hidden;
        width: 0;
      }

      .tm-menu-tooltip {
        display: block;
      }
    }
  }

  &.tm-h-xs {
    .tm-zooms-wrapper {
      display: none;
    }
  }

  &.tm-w-xs {
    .tm-zooms-wrapper {
      .tm-button {
        height: 37px;
        width: 37px;
      }
    }

    .tm-button-bar {
      .tm-notification {
        right: 40%;
        top: 100%;
        width: 200px;
        bottom: -75px;
        height: auto;
      }

      .tm-floor-switcher-mobile {
        .tm-button {
          height: 35px;
          width: 35px;

          .tm-mobile-floor-info {
            display: none;
          }
        }
      }
    }

    &.tm-header-disabled {
      .tm-button-bar {
        top: 20px;
        right: 20px;
      }
    }
  }
}

.tm-station-plan.tm-w-l .tm-zooms-wrapper {
  bottom: 110px;
}
