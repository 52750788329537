@import '~ol/ol.css';
@import 'react-spatial/themes/default/variables.scss';
@import '@geops/react-ui/themes/default/variables.scss';

$brand-primary: #eb0000; // or #eb0000
$brand-secondary: #003d85;
$btn-primary-color-hover: #a20013;
$transparent-background: rgba(255, 255, 255, 0.9);
$gray-lighter: #b7b7b7;
$gray-light: #767676;
$grey-selected: #444;
$btn-size-base: 50px;
$font-family: 'SBBWeb Roman', arial, sans-serif;
$font-family-bold: 'SBBWeb Bold', arial, sans-serif;
$font-family-light: 'SBBWeb Light', arial, sans-serif;

@import 'react-spatial/themes/default/mixins.scss';
@import '@geops/react-ui/themes/default/mixins.scss';
