$font-path: 'https://cdn.app.sbb.ch/fonts/v1_6_subset/';
.tm-menu-tooltip {
  position: absolute;

  .tm-menu-tooltip-body {
    position: absolute;
    padding: 10px;
    height: 13px;
    font-weight: bold;
    font-size: 80%;
    white-space: nowrap;
    min-width: 0;
    background-color: white;
    filter: drop-shadow(0 1px 4px rgba(0, 0, 0, 0.2));
  }
}
