$font-path: 'https://cdn.app.sbb.ch/fonts/v1_6_subset/';
.tm-legend-menu-item {
  cursor: pointer;
  display: flex;
  margin: 12px 6px;
  user-select: none;
  align-items: center;

  .tm-legend-menu-title {
    padding: 0 5px;
    line-height: 25px;
  }

  .tm-legend-menu-icon {
    width: 28px;
    height: 28px;
  }

  &.tm-inactive {
    color: #767676; // for barrierfree contrast purpose.

    svg circle {
      fill: #949494; // for barrierfree contrast purpose.
    }
  }
}

.tm-w-xs {
  .tm-legend-menu-item {
    margin: 5px;
  }
}
