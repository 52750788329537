$font-path: 'https://cdn.app.sbb.ch/fonts/v1_6_subset/';
@import '../../Globals.scss';

.tm-outdoor-plan-button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 52px;
  border: 1px solid #aaa;
  margin-bottom: 20px;
  background: white;
  color: #767676;
  cursor: pointer;
  box-shadow: 0 0 7px rgba(0, 0, 0, 0.4);

  > span {
    &:first-child,
    &:last-child {
      padding: 20px;
      min-width: 40px;
      line-height: 0; // Make text perfectly centered on chrome;
    }

    &:first-child {
      text-align: right;
      margin-left: 8px;
    }

    &:last-child {
      margin-right: 8px;
    }

    &.tm-title-active {
      color: #333;
      font-family: $font-family-bold;
    }

    &:hover {
      color: #333;
    }
  }
}

.tm-w-s,
.tm-w-xs,
.tm-h-xs {
  .tm-outdoor-plan-button {
    height: 35px;

    > span {
      &:first-child,
      &:last-child {
        padding: 5px;
      }
    }
  }
}
