$font-path: 'https://cdn.app.sbb.ch/fonts/v1_6_subset/';
@import '../../Globals.scss';

.tm-station-plan {
  .tm-overlay-close-bt,
  .tm-overlay-integrated-closer {
    svg {
      height: 23px;
      width: 23px;
    }
  }

  .tm-overlay-mobile {
    overflow: auto;
    z-index: 3;
    background-color: white;
    box-shadow: 0 -6px 6px #00000029;
    transition: min-height 0.5s;

    .tm-overlay-close-wrapper {
      display: none;
    }
  }

  .tm-overlay {
    z-index: 3;
    top: 56px;
    bottom: 25px;
    background-color: white;
    height: calc(100% - 81px);
    width: 400px;
    left: 0;
    transition: all 0.3s linear;

    &.tm-overlay-closed {
      left: -400px;
      transition: all 0.2s linear;
    }

    .tm-overlay-body {
      max-height: calc(100% - 40px);
      overflow: auto;
    }

    .tm-overlay-body-small {
      max-height: calc(100% - 240px);
    }

    .tm-overlay-close-wrapper {
      position: absolute;
      display: flex;
      align-items: center;
      background-color: white;
      top: -56px;
      height: 55px;
      width: 310px;

      .tm-overlay-close-bt {
        display: flex;
        align-items: center;
        max-width: 500px;
        margin-left: 10px; // for barrierfree focus

        svg {
          margin: 0 10px;
        }

        &:hover {
          color: $btn-primary-color-hover;

          svg {
            path {
              stroke: $btn-primary-color-hover;
            }
          }
        }
      }
    }
  }

  .tm-overlay-images-wrapper {
    position: relative;

    .tm-overlay-image-loader {
      background-color: white;
      width: 100%;
      height: 200px;
      position: absolute;
      top: 0;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .tm-overlay,
  .tm-overlay-mobile {
    overflow-wrap: break-word;

    .tm-overlay-service-wrapper {
      height: 100%;
      overflow: auto;
    }

    .tm-overlay-integrated-closer {
      position: absolute;
      right: 10px;
      top: 10px;
    }

    .tm-overlay-description {
      padding-top: 30px;
    }

    .tm-overlay-image {
      height: 200px;
      overflow: hidden;

      img {
        width: 100%;
      }
    }

    .tm-overlay-link {
      padding: 40px 0 20px 0;

      .tm-overlay-link-anchor {
        display: flex;
        align-items: center;
        font-size: 18px;
        transition: color 0.3s ease;

        &:hover {
          color: $btn-primary-color-hover;

          svg {
            fill: $btn-primary-color-hover;
          }
        }

        .tm-overlay-link-arrow {
          width: 20px;
          height: 20px;
          margin-left: 5px;
          fill: $btn-primary-color;
        }
      }
    }

    .tm-overlay-body {
      display: flex;
      flex-direction: column;
      padding: 20px;

      a {
        color: #333;
        margin-bottom: 10px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        &:hover {
          color: $btn-primary-color-hover;
        }
      }

      .tm-overlay-payments {
        display: flex;
        flex-flow: wrap;

        img {
          height: 40px;
          padding: 10px;
        }
      }

      .tm-overlay-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 60px;
        font-size: 20px;

        .tm-overlay-logo {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          min-width: 40px;
          height: 100%;

          img {
            max-width: 90%;
            max-height: 100%;
            flex: 0 0 auto;
          }
        }
      }

      .tm-overlay-title {
        font-size: 20px;
        font-family: $font-family-light;
        padding: 40px 0 10px 0;
      }

      .tm-overlay-contact {
        display: flex;
        flex-direction: column;

        div,
        a {
          &:last-child {
            margin-bottom: 0;
          }
        }

        .tm-overlay-contact-url {
          display: flex;
          margin-bottom: 10px;

          a {
            margin-bottom: 0;
            display: flex;
            width: 100%;
          }

          svg {
            width: 20px;
            min-width: 20px;
            height: 21px;
            margin-left: 5px;
            fill: $brand-primary;
          }

          &:hover {
            svg {
              fill: $btn-primary-color-hover;
            }
          }
        }
      }

      .tm-overlay-item {
        padding-bottom: 10px;
      }

      .tm-overlay-holidays {
        border-spacing: 0;
        width: 100%;

        .tm-overlay-holiday {
          width: 100%;
          display: table;
          padding: 5px 0;

          &.tm-hidden {
            display: none;
          }

          td {
            width: 33%;
          }

          .tm-overlay-holiday-hours {
            display: flex;
            flex-direction: column;
            width: 100%;
          }
        }

        .tm-overlay-holiday-toggle {
          padding: 7px 0;
          text-decoration: underline;
        }
      }

      .tm-popup-description {
        font-size: 90%;
        padding-bottom: 5px;
        justify-content: center;
      }
    }

    .tm-hidden {
      display: none;
    }
  }

  .tm-overlay-without-footer {
    bottom: 0;
    height: calc(100% - 56px);
  }

  &.tm-w-m {
    .tm-overlay {
      .tm-overlay-body {
        overflow: unset;
      }
    }
  }
}
