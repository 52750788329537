$font-path: 'https://cdn.app.sbb.ch/fonts/v1_6_subset/';
@import '../../Globals.scss';

.tm-barrierfree-toggler {
  width: 50%;

  .tm-barrierfree-button {
    height: auto;
    width: calc(100% - 10px);
    display: flex;
    align-items: center;
    justify-content: left;

    svg {
      height: 25px;
      width: 25px;
      padding-right: 10px;
    }

    &:hover {
      color: $brand-primary;

      svg {
        path {
          fill: $brand-primary;
        }
      }
    }

    &.tm-active {
      color: $btn-primary-color-hover;

      svg {
        path {
          fill: $btn-primary-color-hover;
        }
      }
    }
  }

  .tm-active {
    &:hover {
      color: $btn-primary-color-hover;
    }
  }
}
