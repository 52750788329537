$font-path: 'https://cdn.app.sbb.ch/fonts/v1_6_subset/';
@import '../../Globals.scss';

.tm-search {
  width: 400px;
  max-width: 400px;
  height: 44px;
  margin: auto;
  position: absolute;
  transition: width 0.3s ease;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  .tm-list li {
    padding: 4px 10px;
  }

  .tm-bt-search {
    background-color: $btn-primary-color;
    color: white;

    svg {
      flex-grow: 1;
      padding: 12px;
      width: 18px;
      fill: currentColor;
    }

    &:hover {
      background-color: $btn-primary-color-hover;
    }
  }

  .tm-search-result {
    display: flex;
    align-items: center;

    .tm-item-search-result-logo {
      display: flex;
      padding-right: 10px;
      justify-content: center;
      align-items: center;
      min-width: 50px;

      img {
        max-width: 50px;
        max-height: 50px;
      }
    }

    .tm-item-search-result-text {
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .tm-item-headline {
      height: 15px;
      line-height: 15px;
      font-size: 0.9em;
      margin-bottom: 5px;
    }
  }
}

.tm-w-xs,
.tm-w-s,
.tm-w-m {
  .tm-search {
    z-index: 1;
    left: auto;
    width: calc(65% - 10px);
  }
}

.tm-w-s,
.tm-w-m {
  .tm-search {
    right: 70px;
  }
}

.tm-w-xs {
  .tm-search {
    right: 5px;

    .tm-autocomplete {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 10px;
      left: 0;
    }
  }
}
