$font-path: 'https://cdn.app.sbb.ch/fonts/v1_6_subset/';
@import '../../Globals.scss';

.tm-overlay-embedded {
  .tm-access-content {
    width: calc(100% - 80px);
    padding: 20px 40px;

    .tm-access-header {
      height: 40px;

      .tm-access-icon {
        height: 36px;
        width: 36px;
      }
    }

    .tm-access-info {
      .tm-access-direction {
        .tm-access-button {
          height: 18px;
          width: 18px;
        }
      }
    }
  }
}

.tm-access-body {
  display: flex;
  align-items: center;
  flex-direction: column;

  .tm-access-image {
    height: 200px;
    overflow: hidden;

    img {
      width: 100%;
    }
  }

  .tm-access-content {
    display: flex;
    flex-direction: column;
    width: calc(100% - 40px);
    padding: 20px;

    .tm-access-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 60px;
      font-size: 20px;

      .tm-access-icon {
        height: 56px;
        width: 56px;

        svg {
          height: 100%;
          width: 100%;
        }
      }
    }

    .tm-access-info {
      display: flex;
      flex-direction: column;

      .tm-button {
        border: none;
      }

      .tm-access-direction {
        display: flex;
        align-items: center;
        color: initial;
        width: auto;
        height: auto;

        .tm-access-button {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 24px;
          width: 24px;
          border: 1px solid #aaa;
          margin: 2px 10px 2px 0;
          color: inherit;
        }

        span {
          flex-grow: 2;
        }

        &:hover,
        .tm-access-button:hover {
          color: $btn-primary-color-hover !important;
        }
      }
    }
  }
}

.tm-overlay-mobile {
  .tm-access-body {
    .tm-overlay-image {
      display: none;
    }
  }
}
