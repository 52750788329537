$font-path: 'https://cdn.app.sbb.ch/fonts/v1_6_subset/';
@import '../../Globals.scss';

.tm-overlay-related-content {
  padding: 20px;

  .tm-related-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 60px;
    font-size: 20px;
    padding-bottom: 15px;
  }

  .tm-overlay-related-item {
    display: flex;
    min-height: 30px;
    padding-bottom: 5px;

    .tm-overlay-related-image {
      padding: 3px 35px 3px 0;

      img {
        max-width: 30px;
      }
    }

    .tm-overlay-related-title {
      display: flex;
      margin-bottom: 10px;
      align-items: center;
      width: 100%;

      .tm-overlay-related-link {
        max-width: 91%;
      }

      .tm-overlay-related-arrow {
        width: 17px;
        height: 17px;
        margin: 3px 0 0 3px;
        fill: $btn-primary-color;
      }

      &:hover {
        a {
          color: $btn-primary-color-hover;
        }

        .tm-overlay-related-arrow {
          fill: $btn-primary-color-hover;
        }
      }
    }
  }
}
