$font-path: 'https://cdn.app.sbb.ch/fonts/v1_6_subset/';
@import '../../Globals.scss';

.tm-single-value-wrapper {
  .tm-single-value-globe {
    height: 28px;
    width: 28px;

    path {
      stroke: $gray-light;
    }
  }

  &:hover {
    color: $btn-primary-color-hover;

    .tm-single-value-globe {
      path {
        stroke: $btn-primary-color-hover;
      }
    }
  }
}
