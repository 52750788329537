$font-path: 'https://cdn.app.sbb.ch/fonts/v1_6_subset/';
@import '../../Globals.scss';

.tm-menu-wrapper {
  position: absolute;
  margin: auto;
  top: calc(100% + 1px);
  left: 0;
  opacity: 0;
  transition: opacity 0.4s ease;
  animation: fade 0.4s ease;
  z-index: 1;

  &.tm-visible {
    opacity: 1;
  }

  &.tm-hidden {
    opacity: 0;
  }

  .tm-menu {
    max-width: 500px;
    background-color: $transparent-background;

    .tm-menu-buttons {
      display: flex;
      padding: 10px 10px 0 10px;
      width: calc(100% - 20px);
      justify-content: space-between;
    }
  }
}
// used for fading in newly added containers
// see https://codeburst.io/animating-dynamically-created-elements-pure-css-c864fdb6e366
@keyframes fade {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
