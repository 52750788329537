$font-path: 'https://cdn.app.sbb.ch/fonts/v1_6_subset/';
@import '../../Globals.scss';

.tm-floor-tooltip {
  .tm-menu-tooltip-body {
    top: -40px;
    right: 10px;
  }
}

.tm-w-s,
.tm-w-xs {
  .tm-floor-tooltip {
    display: none;
  }
}

.tm-floor-switcher-mobile {
  .tm-button {
    flex-direction: column;
  }

  .tm-mobile-floor-info {
    font-size: 60%;
    padding-bottom: 5px;
  }
}
