$font-path: 'https://cdn.app.sbb.ch/fonts/v1_6_subset/';
@import '../../Globals.scss';

.tm-station-plan {
  .tm-overlay,
  .tm-overlay-mobile {
    .tm-overlay-integrated-body {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      height: 50px;

      .tm-overlay-logo {
        padding-right: 30px;

        img {
          max-height: 50px;
        }

        svg {
          height: 50px;
        }
      }

      .tm-overlay-integrated-content {
        display: flex;
        flex-direction: column;

        .tm-overlay-integrated-title {
          font-size: 18px;
        }

        .tm-overlay-link {
          padding: 0;

          .tm-overlay-link-anchor {
            font-size: 15px;
            color: #333;

            &:hover {
              color: $btn-primary-color-hover;
            }

            .tm-overlay-link-arrow {
              height: 17px;
              width: 17px;
              margin-top: 5px;
            }
          }
        }
      }
    }
  }
}
