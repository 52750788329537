$font-path: 'https://cdn.app.sbb.ch/fonts/v1_6_subset/';
.tm-wkp-switcher {
  animation: fade 0.4s ease;
  cursor: pointer !important;
  border: 1px solid #aaa;
  position: absolute;
  bottom: 50px;
  left: 20px;
  height: 25%;
  width: 25%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 0;

  .tm-wkp-map {
    width: 100%;
    height: 100%;
    background: white;
  }

  .tm-wkp-link {
    background: rgba(255, 255, 255, 0.6);
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    line-height: 30px;
    text-align: center;
  }
}

.tm-w-s,
.tm-w-xs {
  .tm-wkp-switcher {
    position: relative;
    top: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 100%;
    height: 100%;
    border: none;
    overflow: hidden;

    .tm-wkp-link {
      background: rgba(255, 255, 255, 1);
    }
  }
}

// used for fading in newly added containers
// see https://codeburst.io/animating-dynamically-created-elements-pure-css-c864fdb6e366
@keyframes fade {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
