$font-path: 'https://cdn.app.sbb.ch/fonts/v1_6_subset/';
@import '../../Globals.scss';

.tm-tooltip {
  position: absolute;
}

.tm-tooltip > div {
  position: absolute;
  padding: 10px;
  top: 20px;
  height: 13px;
  left: 80%;
  font-weight: bold;
  font-size: 80%;
  white-space: nowrap;
  min-width: 0;
  background-color: white;
  filter: drop-shadow(0 1px 4px rgba(0, 0, 0, 0.2));

  .tm-tooltip-body {
    display: flex;
  }
}
