$font-path: 'https://cdn.app.sbb.ch/fonts/v1_6_subset/';
@import '../../Globals.scss';
$tm-destination-input-height: 25px;

.tm-transport-overlay-body {
  width: 100%;
  height: 100%;
  overflow: auto;

  .tm-transport-content {
    padding: 20px;

    .tm-transport-header {
      display: flex;
      align-items: center;
      min-height: 60px;
      font-size: 20px;

      .tm-transport-icon {
        margin-right: 20px;
      }
    }
  }

  .tm-departure-input {
    height: $tm-destination-input-height;
    margin: 10px 0 20px 0;
  }

  .tm-departure-title {
    padding: 10px 0;
  }

  .tm-departure-name {
    color: white;
    background: $brand-secondary;
    border-radius: 2px;
    max-width: 30px;
    padding: 0 2px;
    text-align: center;
    font-size: 12px;
    line-height: 16px;
  }

  .tm-departure-platform {
    border: 1px solid white;
    background: grey;
    color: white;
    font-size: 12px;
    text-align: center;
  }

  .tm-departure-platform.train {
    background: $brand-secondary;
    color: white;
    text-align: center;
  }

  .tm-departure-plaform.train .tm-platform-inner {
    border: 1px solid white;
    border-radius: 3px;
    margin: 1px;
  }

  .departure-platform {
    border: 1px solid $brand-secondary;
  }

  .tm-departure-destination {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .tm-table-wrapper {
    overflow: hidden;
    transition: max-height 0.3s ease;
  }

  .tm-table-wrapper.loading {
    max-height: 0;
  }

  table.tm-departures {
    border-collapse: collapse;
    width: 100%;

    td {
      padding: 5px 3px;
      max-width: 200px;
    }

    tr {
      border-bottom: 2px solid #eee;
    }

    th {
      padding: 5px 0;
      font-weight: normal;
      text-align: left;
    }
  }

  .tm-departure-min {
    color: green;
    font-size: 10px;
    font-weight: bold;
  }

  .tm-loader {
    width: 100%;
    text-align: center;
  }
}

.tm-overlay-mobile {
  .tm-overlay-mobile-content {
    .tm-overlay-related-body {
      height: 100%;
      overflow: auto;
    }
  }
}

.tm-load-spinner {
  -webkit-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
  -webkit-animation: spin 2s infinite linear;
  -moz-animation: spin 2s infinite linear;
  -o-animation: spin 2s infinite linear;
  animation: spin 2s infinite linear;
  font-size: 20px;
  color: $brand-primary;
}

@-moz-keyframes spin {
  from {
    -moz-transform: rotate(0deg);
  }

  to {
    -moz-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.tm-w-xs {
  .tm-transport-overlay-body {
    table.tm-departures {
      td {
        max-width: 140px;
      }
    }
  }
}
